import { handleResponse } from './auth';
import config from "../config";

const countryCode = process.env.GATSBY_COUNTRY_VALUE;

//Using this service from Reservations to simulate request of branch offices
const getBranchOffices = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'countryCode': countryCode,
    },
  };

  const url = new URL(`${config.client_api}/branches/requested`);
  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  }

  return fetch(url, requestOptions).then(handleResponse)
};

//Using this service from Reservations to simulate request of companies
const getCompanies = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'countryCode': countryCode,
    },
  };
  const url = new URL(`${config.client_api}/companies/requested`);
  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  }
  return fetch(url, requestOptions).then(handleResponse);
};

const getByBranchOffice = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'countryCode': countryCode,
    },
  };

  const url = new URL(`${config.client_api}/medicalVigilance/attendancePerBranchOffice`);

  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  }

  return fetch(url, requestOptions).then(handleResponse);
};

const getAgeGenderRange = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'countryCode': countryCode,
    },
  };

  const url = new URL(`${config.client_api}/medicalVigilance/ages`);

  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  }

  return fetch(url, requestOptions).then(handleResponse);
};

const getByTypeOfCare = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'countryCode': countryCode,
    },
  };

  const url = new URL(`${config.client_api}/medicalVigilance/generalPerEvaluationType`);

  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  }
  return fetch(url, requestOptions).then(handleResponse);
};

const getMedicalExpirations = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'countryCode': countryCode,
    },
  };

  const url = new URL(`${config.client_api}/medicalVigilance/getExpirations`);

  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  }

  return fetch(url, requestOptions).then(handleResponse);
};

const downloadSurveillanceXls = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'countryCode': countryCode,
    },
  };

  const url = new URL(`${config.client_api}/medicalVigilance/generateResultsExcel`);
  if (params) {
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]))
  }

  return fetch(url, requestOptions).then(handleResponse);
};

const getCIE10Reports = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'countryCode': countryCode,
    },
  };

  const url = new URL(`${config.client_api}/medicalVigilance/topDiagnostics`);

  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  }

  return fetch(url, requestOptions).then(handleResponse);
};

const getCIE10XlsData = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'countryCode': countryCode,
    },
  };

  const url = new URL(`${config.client_api}/medicalVigilance/generateDiagnosticsExcel`);

  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  }

  return fetch(url, requestOptions).then(handleResponse);
};


export const medicalService = {
  getBranchOffices,
  getCompanies,
  getByBranchOffice,
  getAgeGenderRange,
  getByTypeOfCare,
  getMedicalExpirations,
  downloadSurveillanceXls,
  getCIE10Reports,
  getCIE10XlsData,
};